import styled, { css } from 'styled-components';
import { Cta } from '../../../molecules/CallToAction/CallToAction.styles';
import { InnerWrapper } from '../../../molecules/Section/Section.styles';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { paddingY } from '../../../styles/atoms/spacing';
import Image from '../../../utils/OptimizedImage';

export const MediaWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 0 45%;
  width: 100%;
  ${props =>
    props.centeredImage
      ? `
        position: relative;
        z-index: 2;
      `
      : ''}

  ${atMinWidth.lg`
    width: auto;
  `}

  img {
    max-width: 100%;
    height: auto;
  }

  ${props =>
    props.isFullWidth &&
    css`
      display: block;
      width: 100vw;
      height: 66.667vw;
      position: relative;
      flex: none;

      .gatsby-image-wrapper {
        position: absolute;
        inset: 0;

        img {
          object-fit: cover;
          object-position: left bottom;
        }
      }

      ${atMinWidth.lg`
        position: absolute;
        inset: 0;
        display: flex;
        flex: 0 0 45%;
        justify-content: flex-end;
        width: auto;
        height: auto;

        .gatsby-image-wrapper {
          position: relative;
          flex: 0 0 50%;

          img {
            object-fit: cover;
            object-position: left bottom;
            ${atMinWidth.xl`
              object-position: left 75%;
            `}
          }
        }
      `}

      ${innerProps =>
        !innerProps.isWithMenu &&
        css`
          ${atMinWidth.lg`
            top: 80px;
          `}
        `}
    `}

  ${props =>
    !props.centeredImage &&
    props.expandMedia &&
    css`
      ${atMinWidth.lg`
        flex: none;
        width: calc(50vw + 26.5px);
      `}
    `}
`;

export const ShowVideoContainer = styled.div`
  cursor: pointer;
`;

export const HeroImageStyle = styled(Image)`
  /* Legacy Hero Image Functionality */
  /* width: inherit;

  &,
  img {
    transform: translateX(0px);
    transition: transform 1.2s ease 0s;

    &:hover {
      transform: translateX(10px);
    }
  } */
`;

const Landing = css`
  &.landing {
    padding-top: 96px !important;
    padding-bottom: 96px !important;

    .body-container p:first-of-type {
      font-size: 16px;
    }

    .copyright-hero {
      margin-bottom: -72px !important;
    }

    &.blue {
      padding-top: 0 !important;
    }

    &.bg-light {
      .btn.btn-primary {
        background-color: #00b591;

        &:hover {
          background-color: #00b591 !important;
          border-color: #00b591 !important;
        }
      }
    }
  }
`;

const Animated = css`
  &.animated {
    &::before {
      z-index: 1000;
      opacity: 0.8;

      ${atMinWidth.xl`
        opacity: 1;
      `}
    }

    &::after {
      background-image: none;
    }

    ${InnerWrapper} {
      display: flex;
      flex-flow: column-reverse nowrap;
      align-items: center;
      margin-bottom: 64px;

      ${atMinWidth.md`
        margin-bottom: 96px;
      `}

      ${atMinWidth.md`
        margin-bottom: unset;
      `}
    }

    .lottie-bg {
      position: relative;
      width: 100vw;
      height: auto;
      margin-bottom: -64px;
      transform: scale(1.2) translateX(-5%);

      ${atMinWidth.xl`
        position: absolute;
        top: 0;
        right: 0;
        height: 100% !important;
        width: auto;
        margin-bottom: 0;
        transform: scale(1) translateX(0);
      `}
    }
  }
`;

export const customSectionStyles = css`
  position: relative;
  overflow: hidden;
  background-color: ${props => props.bgColor};

  &.withOutMenu {
    ${InnerWrapper} {
      ${paddingY(23.5)}

      ${atMinWidth.sm`
        ${paddingY(56)}
      `}

      &.full-width {
        padding-top: 24px;
        padding-bottom: 0;
      }
    }
  }

  ${Landing}
  ${Animated}
`;

export const customInnerContentStyles = css`
  padding-top: 72px;

  &.hasMobileForm {
    padding-top: 72px;
  }

  ${atMinWidth.sm`
    padding-top: 96px;

    &.hasMobileForm {
      padding: inherit;
    }
  `}

  ${atMinWidth.md`
    padding-top: 139px;
  `}

  ${atMinWidth.lg`
    padding-top: 147px;
  `}
`;

export const HeroContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: ${props =>
    !props.centeredImage && props.verticallyCentered ? 'center' : 'flex-start'};
  gap: 36px;
  width: 100%;
  margin: auto;

  ${atMinWidth.lg`
    flex-direction: row;
    gap: 32px;
  `}

  ${props =>
    props.centeredImage
      ? atMinWidth.lg`
        flex-direction: column;
        gap: 64px;
      `
      : ''}

  ${props =>
    props.isFullWidth &&
    css`
      padding-top: 64px;
      padding-bottom: 0;

      ${atMinWidth.lg`
        padding-top: 48px;
        padding-bottom: 64px;
      `}

      ${atMinWidth.xl`
        ${paddingY(86)}
      `}

      @media (min-width: 1400px) {
        ${paddingY(132)}
      }

      @media (min-width: 1800px) {
        ${paddingY(186)}
      }

      // custom button styles for full-width
      ${Cta} {
        .cta {
          background: ${colors.base.white};
          border-color: ${colors.base.white};
          color: ${colors.gray[800]};

          &:hover,
          &:focus {
            background: ${colors.gray[200]};
            border-color: ${colors.gray[200]};
          }
        }
      }
    `}
`;

// Main Styles
export default styled.div`
  display: contents;
  ${props =>
    props.centeredImage
      ? `
        position: relative;
      `
      : ''}

  &::after {
    ${props =>
      props.centeredImage
        ? `
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 30%;
          background: ${props.nextSectionColor?.hex || '#fff'};
        `
        : ''}
  }

  .image-link {
    margin-right: 18px;

    @media (max-width: 397px) {
      margin-bottom: 12px;
    }

    img {
      &:hover {
        transition-duration: 0.15s;
        transform: scale(1.1);
      }
    }
  }

  .breadcrumb-item {
    display: none;
    font-size: 12px;
    line-height: 120%;
    text-transform: capitalize;

    &::before {
      display: none;
    }

    &.active {
      padding-left: 0;
    }
  }

  .top-nav {
    display: flex;
    justify-content: center;
    padding-bottom: 60px;

    @media (max-width: 768px) {
      padding-bottom: 23px;
    }

    &.full-width {
      padding-top: 32px;
      padding-bottom: 0;

      ${atMinWidth.md`
        padding-top: 0;
      `}
    }

    & svg {
      width: 210px;
      height: 31px;
    }
  }

  .link-container {
    @media (max-width: 991.98px) {
      button {
        margin-top: 12px;
      }
    }
  }

  .video-container {
    position: relative;

    @media (max-width: 991.98px) {
      margin-top: 20px;
    }

    .gatsby-image-wrapper {
      max-height: 500px;
      max-width: 570px;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
      cursor: pointer;

      .watch-video-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 86px;
        height: 64px;

        span {
          display: inline-block;
          background: #01c9a1;
          width: 100%;
          height: 100%;
          border-radius: 3px;
          padding: 20px 34px;
          transition: all 150ms ease;
          transition-timing-function: cubic-bezier(0.47, 2.02, 1, 0.79);

          &:hover {
            transform: scale(1.1);
          }

          svg {
            font-size: 20px;
          }
        }
      }
    }
  }

  &.form-hero {
    @media (max-width: 767.98px) {
      padding-top: 48px !important;
    }
  }
`;

export const AnimationHeadingStyle = styled.h2`
  height: 60.4px;

  @media (max-width: 991.98px) {
    height: 48.34px;
  }

  span {
    width: 100%;
    overflow: hidden;
    animation: topToBottom ${props => (props.cnt || 4) * 3}s ease-in infinite 0s;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    ${props =>
      Array(props.cnt || 4)
        .fill()
        .map(
          (_, index) =>
            `&:nth-child(${index + 1}) {animation-delay: ${index * 3 - 1.5}s;}`
        )}

    svg {
      width: 100%;
      height: 60.4px;

      @media (max-width: 991.98px) {
        height: 48.34px;
      }
    }
  }

  /*topToBottom Animation*/
  @keyframes topToBottom {
    0% {
      opacity: 0;
    }

    5% {
      opacity: 0;
      transform: translateY(-50px);
      animation-timing-function: ease-out;
    }

    10% {
      opacity: 1;
      transform: translateY(0px);
      animation-timing-function: ease-out;
    }

    25% {
      opacity: 1;
      transform: translateY(0px);
      animation-timing-function: ease-in;
    }

    30% {
      opacity: 0;
      transform: translateY(50px);
      animation-timing-function: ease-in;
    }

    80% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }
`;
